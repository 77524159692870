<script setup>
import {computed, ref, watch} from 'vue';
import {usePage} from '@inertiajs/inertia-vue3';
import {CheckCircleIcon, XCircleIcon} from '@heroicons/vue/24/outline'
import {XMarkIcon} from '@heroicons/vue/20/solid'

const props = defineProps({
	hideAfter: undefined
});

const page = usePage();

const show = ref(true);
const style = computed(() => page.props.value.flash?.bannerStyle || 'success');
const message = computed(() => page.props.value.flash?.banner || '');

watch(message, () => {
	show.value = message.value?.length > 0;
	if (message.value?.length > 0)
		autoHide();
});

const success = computed(() => style.value === 'success');

const timeOutId = ref(null);
const autoHide = () => {
	if (timeOutId.value != null) {
		//stop old one and start a new one.
		clearTimeout(timeOutId);
		timeOutId.value = null;
	}

	timeOutId.value = setTimeout(() => {
		page.props.value.flash.banner = null;
	}, props.hideAfter != undefined ? props.hideAfter : 2000);
};

</script>

<template>
	<div aria-live="assertive"
	     class="z-50 pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6">
		<div class="flex w-full flex-col items-center space-y-4 sm:items-end">
			<!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
			<transition enter-active-class="transform ease-out duration-300 transition"
			            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
			            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
			            leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
			            leave-to-class="opacity-0">
				<div v-if="show && message"
				     :class="success ? 'bg-green-50' : 'bg-red-50'"
				     class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
					<div class="p-4">
						<div class="flex items-start">
							<div class="flex-shrink-0">
								<CheckCircleIcon v-if="success" class="h-6 w-6 text-green-600" aria-hidden="true"/>
								<XCircleIcon v-if="!success" class="h-6 w-6 text-red-600" aria-hidden="true"/>
							</div>
							<div class="ml-3 w-0 flex-1 pt-0.5">
								<p class="text-sm font-medium"
								   :class="success ? 'text-green-600' : 'text-red-600'"
								>{{ message }}</p>
								<p class="mt-1 text-sm text-gray-500"></p>
							</div>
							<div class="ml-4 flex flex-shrink-0">
								<button type="button" @click="show = false"
								        :class="success ? 'text-green-600' : 'text-red-600'"
								        class="inline-flex rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-0">
									<span class="sr-only">Close</span>
									<XMarkIcon class="h-5 w-5" aria-hidden="true"/>
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>